"use client";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";

import { cn } from "@/lib/utils";
import { Route } from "next";
import Link from "next/link";
import { forwardRef } from "react";

const menu = [
  {
    title: "Features",
    items: [
      {
        title: "Timesheets",
        url: "/timesheets",
        icon: "timesheets",
        desc: "List all previous timesheets",
      },
      {
        title: "Clients",
        url: "/timesheets",
        icon: "timesheets",
        desc: "Create a new timesheet",
      },
      {
        title: "Invoices",
        url: "/timesheets",
        icon: "timesheets",
        desc: "Create a new timesheet",
      },
      {
        title: "Clients",
        url: "/timesheets",
        icon: "timesheets",
        desc: "Create a new timesheet",
      },
    ],
  },
  {
    title: "Pricing",
    url: "/pricing",
  },
  {
    title: "About",
    items: [
      {
        title: "About",
        url: "/clients",
        icon: "timesheets",
        desc: "More about us",
      },
      {
        title: "Contact us",
        url: "/contact",
        icon: "timesheets",
        desc: "Have a question?",
      },
    ],
  },
];

export default function Navigation() {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        {menu.map((item, index) => (
          <div key={index}>
            {item.items && (
              <NavigationMenuItem>
                <NavigationMenuTrigger>{item.title}</NavigationMenuTrigger>

                <NavigationMenuContent>
                  <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[1fr_1fr]">
                    {item.items.map((subitem) => (
                      <ListItem
                        href={subitem.url}
                        title={subitem.title}
                        key={subitem.url}
                      >
                        {subitem.desc}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            )}
            {item.url && (
              <Link href={item.url as Route} legacyBehavior passHref>
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                  {item.title}
                </NavigationMenuLink>
              </Link>
            )}
          </div>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li key={title}>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className,
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
